import WEH from "./weh-qr-linkedin.png"
import NPK from "./npk-qr-linkedin.png"
import Logo from "./Small-Logo.svg"

export const config = {
    loopsUrl:
        "https://app.loops.so/api/newsletter-form/clgzhyqf9001ele0gc57oyvoi",
    spamProtectPass: "zzz",
    contacts: [
        { img: WEH, name: "Will Harford (CTO)" },
        { img: NPK, name: "Natasha Popoek-Konieczko (CEO)" },
    ],
    Logo: Logo,
}
