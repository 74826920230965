import { TextField } from "@fluentui/react"
import { useEffect, useState } from "react"
import { config } from "./config"

export function Protect(props) {
    const [unlocked, setUnlocked] = useState(
        config.spamProtectPass === "" ? true : false
    )

    useEffect(() => {
        document.getElementById("protect")?.focus()
    })

    if (unlocked) {
        return props.children
    } else {
        return (
            <TextField
                id="protect"
                onChange={(ev, value) => {
                    if (value.toLowerCase() === config.spamProtectPass) {
                        setUnlocked(true)
                    }
                }}
            />
        )
    }
}
