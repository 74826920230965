import React, { useEffect, useState } from "react"
import {
    DefaultButton,
    PrimaryButton,
    Pivot,
    PivotItem,
    Stack,
    TextField,
    useTheme,
    Modal,
    MessageBar,
    MessageBarType,
    Text,
    Spinner,
    ThemeProvider,
} from "@fluentui/react"
import "./App.css"

import { config } from "./config"

const meetingPlace = "Startupfest 2023"

const appTheme = {
    fonts: {
        small: {
            fontSize: "18px",
        },
        medium: {
            fontSize: "20px",
        },
        large: {
            fontSize: "20px",
            fontWeight: "semibold",
        },
        xLarge: {
            fontSize: "25px",
            fontWeight: "semibold",
        },
    },
    components: {
        TextField: {
            styles: {
                fieldGroup: {
                    height: "50px",
                },
            },
        },
        PrimaryButton: {
            styles: {
                root: {
                    height: "50px",
                },
            },
        },
    },
}

export default function App() {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const theme = useTheme()

    return (
        <ThemeProvider theme={appTheme}>
            <div
                style={{
                    padding: theme.spacing.m,
                    background: theme.palette.neutralLight,
                }}
            >
                <img src={config.Logo} /> <Text>keeping in touch</Text>
            </div>
            {error && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                >
                    <Text> {error}</Text>
                </MessageBar>
            )}
            {loading && (
                <div
                    style={{
                        position: "absolute",
                        marginLeft: "auto",
                        marginRight: "auto",
                        left: 0,
                        right: 0,
                        textAlign: "center",
                    }}
                >
                    <Spinner label="Submitting Request" />
                </div>
            )}

            <Pivot aria-label="Prospecer Contact">
                <PivotItem headerText="Investor">
                    <ContactForm
                        setError={setError}
                        setLoading={setLoading}
                        userGroup="Investor"
                    />
                </PivotItem>
                <PivotItem headerText="Mentor">
                    <ContactForm
                        setError={setError}
                        setLoading={setLoading}
                        userGroup="Mentor"
                    />
                </PivotItem>
                <PivotItem headerText="Friend">
                    <ContactForm
                        setError={setError}
                        setLoading={setLoading}
                        userGroup="Friend"
                    />
                </PivotItem>
                <PivotItem headerText="LinkedIn">
                    <QrContacts />
                </PivotItem>
            </Pivot>
        </ThemeProvider>
    )
}

function QrContacts(props) {
    const contacts = []
    for (const c of config.contacts) {
        contacts.push(
            <div key={c.name}>
                <Text variant="large">
                    <b>{c.name}</b>
                </Text>
                <img src={c.img} alt={c.name} style={{ width: "100%" }} />
                <br />
                <br />
                <br /> <br />
            </div>
        )
    }
    return <>{contacts}</>
}

function ContactForm(props) {
    const theme = useTheme()
    const [contact, setContact] = useState({})
    const userGroup = props.userGroup
    const [showThanks, setShowThanks] = useState(false)
    const setError = props.setError
    const setLoading = props.setLoading

    function setValue(key, value) {
        setContact({ ...contact, [key]: value })
    }

    useEffect(() => {
        if (showThanks) {
            setTimeout(() => {
                setShowThanks(false)
            }, 3000)
        }
    }, [showThanks])

    function submit() {
        var formBody =
            "userGroup=" +
            userGroup +
            "&email=" +
            encodeURIComponent(contact.email) +
            "&firstName=" +
            contact.firstName +
            "&lastName=" +
            contact.lastName +
            "&metAt=" +
            meetingPlace

        setLoading(true)
        fetch(config.loopsUrl, {
            method: "POST",
            body: formBody,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
            .then((res) => [res.ok, res.json(), res])
            .then(([ok, dataPromise, res]) => {
                if (ok) {
                    setError(null)
                    setShowThanks(true)
                    setContact({ firstName: "", lastName: "", email: "" })
                } else {
                    setError(
                        "There was a problem on the form. Please check the inputs"
                    )
                }
                setLoading(false)
            })
            .catch((e) => {
                setError("Too many requests. Please wait a few seconds")
                setLoading(false)
            })
    }
    return (
        <div style={{ padding: theme.spacing.l1 }}>
            <Modal isOpen={showThanks}>
                <div style={{ padding: theme.spacing.l1 }}>
                    <h1>Thank you! We will be in touch.</h1>
                </div>
            </Modal>
            <Stack>
                <TextField
                    label="First Name"
                    value={contact.firstName}
                    onChange={(ev, value) => {
                        setValue("firstName", value)
                    }}
                    required
                />
                <TextField
                    label="Last Name"
                    value={contact.lastName}
                    onChange={(ev, value) => {
                        setValue("lastName", value)
                    }}
                />
                <TextField
                    label="Email"
                    value={contact.email}
                    onChange={(ev, value) => {
                        setValue("email", value)
                    }}
                    required
                />
                <br />
                <PrimaryButton
                    onClick={() => {
                        submit()
                    }}
                >
                    Submit
                </PrimaryButton>
            </Stack>
        </div>
    )
}
